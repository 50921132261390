import { useSelector } from "react-redux";
import { RootState } from "../redux/app";
import { USER_ROLES } from "../types/constants";
import AdminLayout from "./AdminLayout";

// deciding layout
const MainLayout = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  // get current user info
  // const getCurrentUser = async () => {
  //   const response = await axiosInstance.get(`/users/getCurrentUser`);
  //   const { data } = response.data;

  //   return data?.data;
  // };

  // const { data, error } = useQuery<UserObj | undefined, any, UserObj, string[]>(
  //   ["getCurrentUser"],
  //   getCurrentUser
  // );

  // useEffect(() => {
  //   if (data) {
  //     dispatch(
  //       setCurrentUser({
  //         _id: data._id,
  //         username: data.username,
  //         email: data.email,
  //         profile: data.profile,
  //         role: data.role,
  //       })
  //     );
  //   }
  // }, [data]);

  if (user) {
    if (user.role === USER_ROLES.ADMIN) {
      return <AdminLayout />;
    }
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {/* {error ? "Something went wrong, please refresh the screen" : "Loading..."} */}
      {"Loading..."}
    </div>
  );
};

export default MainLayout;
