
interface Props {
  label: string;
  type: string;
  id: string;
  name: string;
  autoFocus?: boolean;
  optional?: boolean;
  inline?: boolean;
  readonly?: boolean;
  classes?: string;
  step?: string;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void
}

const SimpleInput = ({
  label,
  type,
  id,
  name,
  classes,
  readonly = false,
  autoFocus = false,
  optional = false,
  inline = false,
  step = "1",
  placeholder,
  value,
  onChange,
  ...props
}: Props) => {
  //   field -> { name: string, value: string, onChange: () => {}, onBlur: () => {} }
  //   meta -> { touched: boolean, error: string, ... }
  // const [field, meta] = useField({ name, type });

  return (
    <div className={`flex flex-col w-full h-16 mb-6 ${classes}`}>
      <div
        className={`form-group flex ${inline ? " flex-row items-center" : "flex-col justify-center"
          }`}
      >
        <label
          className={`font-semibold text-sm ${inline ? "mr-4 w-min" : "mb-2"}`}
          htmlFor={id}
        >
          {label}{" "}
          {optional && <span className="optional text-slate-400">(O)</span>}
        </label>

        {type === "number" ? (
          <input
            disabled={readonly}
            readOnly={readonly}
            autoFocus={autoFocus}
            className={"border-slate-200 focus:border-primary"}
            {...props}
            id={id}
            type={type}
            min="0"
            step={step}
            value={value}
            placeholder={placeholder}
            onChange={(e) => { onChange(e.target.value) }}
            {...props}
          />
        ) : (
          <input
            disabled={readonly}
            readOnly={readonly}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={"border-slate-200 focus:border-primary"}
            {...props}
            id={id}
            onChange={(e) => { onChange(e.target.value) }}
            value={value}
            type={type}
          />
        )}
      </div>
    </div>
  );
};

export default SimpleInput;
