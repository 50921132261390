import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface FormValues {
    project_name: string;
    project_location: string;
    person_phone: string;
    person_name: string;
}

interface Props {
    isUpdate?: boolean;
    data?: any;
}

const AddNewProjectModal = (props: Props) => {
    const { isUpdate, data } = props;
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const initialValues: FormValues = {
        project_name: data.project_name ? data.project_name : "",
        project_location: data.project_location ? data.project_location : "",
        person_phone: data.project_contact_details.person_phone
            ? data.project_contact_details.person_phone
            : "",
        person_name: data.project_contact_details.person_name,
    };

    const validationSchema = Yup.object({
        project_name: Yup.string().required("Required"),
        project_location: Yup.string().required("Required"),
        person_phone: Yup.string()
            .required("Required")
            .min(10, "Invalid Phone Number")
            .max(10, "Invalid Phone Number"),
        person_name: Yup.string().required("Required"),
    });

    const handleSubmit = async (project: FormValues) => {
        setIsSubmitting(true);
        try {
            const obj = {
                project_name: project.project_name,
                project_location: project.project_location,
                project_contact_details: {
                    person_phone: project.person_phone.toString(),
                    person_name: project.person_name,
                },
            };
            let result = null;
            if (isUpdate) {
                result = await axiosInstance.patch(
                    "/project/update/" + data.project_id,
                    obj
                );
            } else {
                result = await axiosInstance.post("/project/create", obj);
            }
            if (result) {
                setIsSubmitting(false);
                queryClient.invalidateQueries(["getAllProjectsDetails"]);
                dispatch(
                    addToast({
                        kind: SUCCESS,
                        msg: isUpdate
                            ? "Project update successfully!"
                            : "Project added successfully!",
                    })
                );
                dispatch(hideModal());
            }
        } catch (error: any) {
            setIsSubmitting(false);

            if (error.response) {
                const response = error.response;
                const { msg } = response.data;

                switch (response.status) {
                    // bad request or invalid format or unauthorized
                    case 400:
                    case 500:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: msg,
                            })
                        );
                        break;
                    default:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: "Oops, something went wrong",
                            })
                        );
                        break;
                }
            } else if (error.request) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
        setIsSubmitting(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form
                className="px-6 py-4 mt-2"
                style={{
                    minWidth: "360px",
                    maxWidth: "760px",
                }}
            >
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Project Name"
                        id="project_name"
                        name="project_name"
                        type="text"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Project Location"
                        id="project_location"
                        name="project_location"
                        type="text"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Person Phone"
                        id="person_phone"
                        name="person_phone"
                        type="number"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Person Name"
                        id="person_name"
                        name="person_name"
                        type="text"
                    />
                </div>
                <div className="buttons flex items-center w-full justify-center my-4">
                    <SubmitBtn
                        text="Save"
                        isSubmitting={isSubmitting}
                        classes="text-sm"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default AddNewProjectModal;
