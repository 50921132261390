import { SequestrationData } from "../../types";

interface ViewSequestrationModalProps {
    data: SequestrationData;
}

const ViewSequestrationModal = (props: ViewSequestrationModalProps) => {
    const {
        address,
        batch_date,
        compost_details,
        final_image,
        incorporation_time,
        mixing_image,
        sequestration_type,
        updated_at,
    } = props.data;
    console.log(props.data);
    return (
        <div
            className="px-6 py-4 mt-2"
            style={{
                minWidth: "360px",
                maxWidth: "760px",
            }}
        >
            <div className="flex flex-row item-center gap-x-6">
                <div className="text-xl flex flex-col gap-4">
                    <p>Sequestration Type : {sequestration_type}</p>
                    <p>
                        Address :{" "}
                        {address && address.length > 0
                            ? address[0].address
                            : "No address present"}
                    </p>
                    <p>Batch Date : {batch_date.slice(0, 10)}</p>
                    <p>Incorporation Time : {incorporation_time.slice(0, 10) +" (" + incorporation_time.slice(11,16) + ")"}</p>
                    <p>
                        Mixing Image:{" "}
                        <a
                            href={mixing_image}
                            className="text-green-400"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Open Image
                        </a>
                    </p>
                    <p className="flex gap-4">
                        Compost Details :{" "}
                        <div className="flex flex-col justify-start items-start gap-4">
                            {compost_details ? (
                                Object.entries(compost_details).map(
                                    ([key, value]) => {
                                        return <span>{key + " : " + value}</span>;
                                    }
                                )
                            ) : (
                                <span>No Details</span>
                            )}
                        </div>
                    </p>
                    <p>
                        Final Image:{" "}
                        <a
                            href={final_image}
                            className="text-green-400"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Open Image
                        </a>
                    </p>
                    <p>Updated At : {updated_at.slice(0, 10)}</p>
                </div>
            </div>
        </div>
    );
};

export default ViewSequestrationModal;
