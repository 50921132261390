import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import ErrorBox from "../../components/FormikComponents/ErrorBox";
import Input from "../../components/FormikComponents/Input";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import { loginUser } from "../../redux/features/authSlice";

interface UserObj {
  phoneNumber: string;
  otp?: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingOTP, setIsSubmittingOTP] = useState(false);
  const [otpReceived, setOtpReceived] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resetTimer, setResetTimer] = useState(30);
  const initalValues: UserObj = {
    phoneNumber: "",
    otp: "",
  };
  const initalValuesOTP: UserObj = {
    phoneNumber: phoneNumber,
    otp: "XXXXXX",
  };
  const [commonError, setCommonError] = useState("");

  useEffect(() => {
    setResetTimer(30);
  }, [otpReceived]);

  useEffect(() => {
    let timerCount: NodeJS.Timeout = setTimeout(() => {
      if (resetTimer > 0) setResetTimer(resetTimer - 1);
    }, 1000);
    if (resetTimer === 0) {
    }
    return () => clearTimeout(timerCount);
  }, [resetTimer]);
  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Invalid. Phone Number must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
  });

  const validationSchemaOTP = Yup.object({
    otp: Yup.string()
      .required("OTP is required")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
  });

  const handleSubmit = useCallback((user: UserObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(
        `/users/login`,
        { phonenumber: user?.phoneNumber },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        const data = response.data;
        console.log(data);
        setCommonError("");
        setPhoneNumber(user?.phoneNumber);
        setIsSubmitting(false);
        setOtpReceived(true);
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 403:
            case 500:
              setCommonError(msg);
              break;
            case 404:
              setCommonError("No user found with the given number.");
              break;
            default:
              setCommonError("Oops, something went wrong");
              break;
          }
        } else if (error.request) {
          setCommonError("Oops, something went wrong");
        } else {
          setCommonError(`Error: ${error.message}`);
        }
      });
  }, []);
  const handleSubmitOTP = useCallback(
    (user: UserObj) => {
      setIsSubmittingOTP(true);

      axiosInstance
        .post(
          `/users/login/verify`,
          {
            phonenumber: user?.phoneNumber,
            otp: user?.otp,
          },
          {
            headers: {
              ContentType: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response, "verify");
          const data = response.data?.data;

          setCommonError("");

          setIsSubmittingOTP(false);

          dispatch(
            loginUser({
              accessToken: data.token,
              refreshToken: "",
            })
          );
        })
        .catch((error: AxiosError) => {
          setIsSubmittingOTP(false);

          if (error.response) {
            const response = error.response;
            const { msg } = response.data;

            switch (response.status) {
              // bad request or invalid format or unauthorized
              case 400:
              case 403:
              case 500:
                setCommonError(msg);
                break;
              default:
                setCommonError("Oops, something went wrong");
                break;
            }
          } else if (error.request) {
            setCommonError("Oops, something went wrong");
          } else {
            setCommonError(`Error: ${error.message}`);
          }
        });
    },
    [dispatch]
  );
  const handleResendOTP = () => {
    axiosInstance
      .post(
        `/users/login`,
        { phonenumber: phoneNumber },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        console.log(data);
        setCommonError("");
        setResetTimer(30);
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 403:
            case 500:
              setCommonError(msg);
              break;
            case 404:
              setCommonError("No user found with the given number.");
              break;
            default:
              setCommonError("Oops, something went wrong");
              break;
          }
        } else if (error.request) {
          setCommonError("Oops, something went wrong");
        } else {
          setCommonError(`Error: ${error.message}`);
        }
      });
  };

  return (
    <>
      {!otpReceived ? (
        <Formik
          initialValues={initalValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form
            className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
            style={{
              minWidth: "360px",
            }}
          >
            <img src={logo} alt="logo" height={100} width={100} />
            <Input
              label="Phone Number"
              id="phoneNumber"
              name="phoneNumber"
              type="string"
            />
            {otpReceived && (
              <Input label="OTP" id="otp" name="otp" type="number" />
            )}

            {commonError && (
              <div className="common-error mb-2 text-center">
                <ErrorBox msg={commonError} />
              </div>
            )}

            <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
              <SubmitBtn
                text="Get OTP"
                isSubmitting={isSubmitting}
                classes="text-sm"
              />
            </div>

            {/* <div className="forgot-otp mb-4 text-sm">
          <Link to="/forgot-otp" className="text-primary">
            Forgot Password?
          </Link>
        </div> */}
          </Form>
        </Formik>
      ) : (
        <Formik
          initialValues={initalValuesOTP}
          validationSchema={validationSchemaOTP}
          onSubmit={(values) => handleSubmitOTP(values)}
        >
          <Form
            className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
            style={{
              minWidth: "360px",
            }}
          >
            <img src={logo} alt="logo" height={100} width={100} />

            {!otpReceived && (
              <Input
                label="Phone Number"
                id="phoneNumber"
                name="phoneNumber"
                type="string"
              />
            )}
            <Input label="OTP" id="otp" name="otp" type="number" />

            {commonError && (
              <div className="common-error mb-2 text-center">
                <ErrorBox msg={commonError} />
              </div>
            )}

            <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
              <SubmitBtn
                text="Login with OTP"
                isSubmitting={isSubmitting}
                classes="text-sm"
              />
              <button
                title="Resend OTP"
                disabled={resetTimer > 0}
                className="text-sm w-full mt-2 bg-black text-white p-2"
                onClick={handleResendOTP}
              >
                Resend OTP {resetTimer > 0 ? "(" + resetTimer + ")" : ""}
              </button>
            </div>

            {/* <div className="forgot-otp mb-4 text-sm">
          <Link to="/forgot-otp" className="text-primary">
            Forgot Password?
          </Link>
        </div> */}
          </Form>
        </Formik>
      )}
    </>
  );
};

export default Login;
