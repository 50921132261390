import React from "react";
import { BsDiamondFill } from "react-icons/bs";
import { SUBTITLE, TITLE } from "../../config";

const Logo = () => {
  return (
    <div className="logo flex items-center">
      <div className="left mr-3">
        <BsDiamondFill size={30} />
      </div>
      <div className="right">
        <h3 className="font-bold text-xl leading-none">{TITLE}</h3>
        <span className="text-xs font-semibold">{SUBTITLE}</span>
      </div>
    </div>
  );
};

export default Logo;
