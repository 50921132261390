import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { NewFertilizer } from "../../types";
import { ERROR, SUCCESS, USER_ROLES_CREATION } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";

const AddFertiliserModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const editAccessOptions = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];
  const userRoleOptions = Object.values(USER_ROLES_CREATION).map(
    (r: string) => {
      return {
        label: r,
        value: r,
      };
    }
  );

  const initialValues: NewFertilizer = {
    type: "simple",
    name: "",
    nitrogen: 0,
    phosphorus: 0,
    potassium: 0,
    sulphur: 0,
    calcium: 0,
    category: "organic",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Fertiliser name is Required"),
    type: Yup.string().required("Fertiliser type is Required"),
    nitrogen: Yup.number().required("Nitrogen value is Required"),
    phosphorus: Yup.number().required("Phosphorus value is Required"),
    potassium: Yup.number().required("Potassium value is Required"),
    category: Yup.string().required("Fertiliser category is Required"),
  });

  const handleSubmit = useCallback((fertiliser: NewFertilizer) => {
    setIsSubmitting(true);

    axiosInstance
      .post(`/users/fertilizers/add`, fertiliser, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getFertilisers"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Fertiliser Added Successfully!",
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex flex-col item-center gap-x-6">
          <div>
            <Input label="Fertiliser Name" id="name" name="name" type="text" />
          </div>
          <div>
            <Select
              options={[
                { value: "simple", label: "Simple" },
                { value: "complex", label: "Complex" },
              ]}
              label="Type"
              id="type"
              name="type"
            />
          </div>
          <div>
            <Select
              options={[
                { value: "organic", label: "Organic" },
                { value: "inorganic", label: "Inorganic" },
              ]}
              label="Category"
              id="category"
              name="category"
            />
          </div>
          <div>
            <Input
              label="Nitrogen"
              id="nitrogen"
              name="nitrogen"
              type="number"
            />
          </div>
          <div>
            <Input
              label="Phosphorous"
              id="phosphorus"
              name="phosphorus"
              type="number"
            />
          </div>
          <div>
            <Input
              label="Potassium"
              id="potassium"
              name="potassium"
              type="number"
            />
          </div>
        </div>

        {/* <Select
          label="Edit Access"
          id="editAccess"
          name="editAccess"
          options={editAccessOptions}
        /> */}

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default AddFertiliserModal;
