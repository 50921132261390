import { PyrolysisFormData } from "../../types";

interface ViewPreparationModalProps {
    data: PyrolysisFormData;
}

const ViewPyrolysisModal = (props: ViewPreparationModalProps) => {
    const {
        start_time,
        end_time,
        initial_stage_image,
        ongoing_stage_image,
        final_stage_image,
    } = props.data;
    console.log(props);
    return (
        <div
            className="px-6 py-4 mt-2"
            style={{
                minWidth: "360px",
                maxWidth: "760px",
            }}
        >
            <div className="flex flex-row item-center gap-x-6">
                <div className="text-xl flex flex-col gap-4">
                    <p>Start Date : {start_time.slice(0,10)+" (" + start_time.slice(11,16) + ")"}</p>
                    <p>
                        Initial Image:{" "}
                        <a
                            href={initial_stage_image}
                            className="text-green-400"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Open Image
                        </a>
                    </p>
                    <p>
                        Ongoing Image:{" "}
                        <a
                            href={ongoing_stage_image}
                            className="text-green-400"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Open Image
                        </a>
                    </p>
                    <p>
                        Final Image:{" "}
                        <a
                            href={final_stage_image}
                            className="text-green-400"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Open Image
                        </a>
                    </p>
                    <p>End Date : {end_time.slice(0,10)+" (" + end_time.slice(11,16) + ")"}</p>
                </div>
            </div>
        </div>
    );
};

export default ViewPyrolysisModal;
