import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { uploadImage } from "../../http/uploadImage";

interface FormValues {
  pit_name: string;
  pit_length: number;
  pit_width: number;
  pit_height: number;
  pit_photo: string;
  address: string;
}

const AddSoilPitModal = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [img, setImg] = useState<Object | null>(null);
    const initialValues: FormValues = {
        pit_name: "",
        pit_length: 0,
        pit_width: 0,
        pit_height: 0,
        pit_photo: "",
        address: ""
    };

    const validationSchema = Yup.object({
        pit_name: Yup.string().required("Required"),
        pit_length: Yup.number().required("Required"),
        pit_width: Yup.number().required("Required"),
        pit_height: Yup.number().required("Required"),
        address: Yup.string().required("Required"),
    });

    const handleSubmit = async (soil_pit: FormValues) => {
        let lat = "12.971599";
        let long = "77.594566"
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition((pos) => {
                lat = pos.coords.latitude.toString();
                long = pos.coords.longitude.toString();
            });
        }
        if (!img) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "Image is required",
                })
            );
            return;
        }
        setIsSubmitting(true);
        try {
            const imgRes = await uploadImage(img);
            if (imgRes.data.publicUrl) {
                const obj = {
                    ...soil_pit,
                    pit_photo: imgRes.data.publicUrl,
                    lat: lat,
                    lng: long,
                };
                console.log(obj);
                const result = await axiosInstance.post(
                    "/soil_pit/create",
                    obj
                );
                if (result) {
                    console.log(result);
                    setIsSubmitting(false);
                    queryClient.invalidateQueries(["getAllSoilPitDetails"]);
                    dispatch(
                        addToast({
                            kind: SUCCESS,
                            msg: "Soil pit added successfully!",
                        })
                    );
                    dispatch(hideModal());
                }
            }
        } catch (error: any) {
            setIsSubmitting(false);

            if (error.response) {
                const response = error.response;
                const { msg } = response.data;

                switch (response.status) {
                    // bad request or invalid format or unauthorized
                    case 400:
                    case 500:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: msg,
                            })
                        );
                        break;
                    default:
                        dispatch(
                            addToast({
                                kind: ERROR,
                                msg: "Oops, something went wrong",
                            })
                        );
                        break;
                }
            } else if (error.request) {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: "Oops, something went wrong",
                    })
                );
            } else {
                dispatch(
                    addToast({
                        kind: ERROR,
                        msg: `Error: ${error.message}`,
                    })
                );
            }
        }
        setIsSubmitting(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form
                className="px-6 py-4 mt-2"
                style={{
                    minWidth: "360px",
                    maxWidth: "760px",
                }}
            >
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Pit Name"
                        id="pit_name"
                        name="pit_name"
                        type="text"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Pit Length (in cm)"
                        id="pit_length"
                        name="pit_length"
                        type="text"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Pit Width (in cm)"
                        id="pit_width"
                        name="pit_width"
                        type="number"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Pit Height (in cm)"
                        id="pit_height"
                        name="pit_height" 
                        type="number"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Address"
                        id="address"
                        name="address"
                        type="text"
                    />
                </div>
                <div className="flex item-center gap-x-6">
                    <Input
                        label="Pit Photo"
                        id="pit_photo"
                        name="pit_photo"
                        type="file"
                        accept=".jpg,.png,.jpeg,.wbep"
                        disabled={!!img}
                        onChange={(e) => {
                            if (e.target.files) {
                                setImg(e.target.files[0]);
                            }
                        }}
                    />
                </div>
                <div className="buttons flex items-center w-full justify-center my-4">
                    <SubmitBtn
                        text="Save"
                        isSubmitting={isSubmitting}
                        classes="text-sm"
                    />
                </div>
            </Form>
        </Formik>
    );
};

export default AddSoilPitModal;
