const getPageTitle = (pathname: string): string => {
  let title = "";

  switch (pathname) {
    case "/":
      title = "Dashboard";
      break;
    case "/users":
    case "/users/accounts":
    case "/users/sales":
    case "/users/purchases":
    case "/users/market":
    case "/users/buyers":
      title = "Users";
      break;
    case "/products":
    case "/products/deleted":
      title = "Products";
      break;
    case "/categories":
      title = "Product Categories";
      break;
    case "/crops":
      title = "Crops";
      break;
    case "/inventory":
      title = "Available Stock";
      break;
    case "/accounts":
    case "/accounts/debtors":
    case "/accounts/creditors":
    case "/accounts/orders/pending-orders":
    case "/accounts/orders/approved-orders":
    case "/accounts/orders/declined-orders":
      title = "Accounts";
      break;
    case "/sales":
    case "/sales/created":
    case "/sales/challaans":
    case "/sales/invoices":
      title = "Sales";
      break;
    case "/purchases":
      title = "Purchase";
      break;
    case "/hr":
      title = "HR";
      break;
    case "/customers":
      title = "Customer";
      break;
    case "/configuration":
    case "/configuration/head":
    case "/configuration/company":
    case "/configuration/bank-account":
    case "/configuration/transport":
      title = "Configuration";
      break;
    case "/discount":
      title = "Discount";
      break;
    case "/supplier":
      title = "Supplier";
      break;
    default:
      title = "";
  }

  return title;
};

export default getPageTitle;
