import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import useEscClose from "../../hooks/useEscClose";
import { hideModal } from "../../redux/features/modalSlice";
import { ModalObj } from "../../types";
import {
    ADD_BIOMASS_COMPOSE_MODAL,
    ADD_CATEGORY_MODAL,
    ADD_CROP_MODAL,
    ADD_CROP_VARIETY_MODAL,
    ADD_FERTILISER_COMBINATION_MODAL,
    ADD_FERTILISER_MODAL,
    ADD_ICAR_MODAL,
    ADD_MEASURING_VESSEL_MODAL,
    ADD_NEW_PROJECT_MODAL,
    ADD_PYROLYSIS_KILN_MODAL,
    ADD_SEED_TYPE_MODAL,
    ADD_SEED_VARIETY_MODAL,
    ADD_SOIL_PIT_MODAL,
    APPROVE_ARTISAN_MODAL,
    ASSIGN_BIOMASS_MODAL,
    EDIT_ICAR_MODAL,
    EDIT_PRODUCT_MODAL,
    VIEW_ARTISAN_OR_PROJECT_MODAL,
    VIEW_BIOCHAR_MODAL,
    VIEW_PREPARATION_MODAL,
    VIEW_PYROLYSIS_MODAL,
    VIEW_SEQUESTRATION_MODAL,
} from "../../types/constants";
import AddCategoryModal from "../ModalComponents/AddCategoryModal";
import AddCropModal from "../ModalComponents/AddCropModal";
import AddCropVarietyModal from "../ModalComponents/AddCropVarietyModal";
import AddFertiliserModal from "../ModalComponents/AddFertiliserModal";
import AddSeedTypeModal from "../ModalComponents/AddSeedTypeModal";
import EditProductModal from "../ModalComponents/EditProductModal";
import AddICARModal from "../ModalComponents/AddICARModal";
import AddFertiliserCombinationModal from "../ModalComponents/AddFertiliserCombinationModal";
import EditICARModal from "../ModalComponents/EditICARModal";
import AddSeedVarietyModal from "../ModalComponents/AddSeedVarietyModal";
import ViewPreparationModal from "../ModalComponents/ViewPreparationModal";
import ViewPyrolysisModal from "../ModalComponents/ViewPyrolysisModal";
import ViewBioCharModal from "../ModalComponents/ViewBioCharModal";
import AddBiomassComposeModal from "../ModalComponents/AddBiomassComposeModal";
import AddPyrolysisKilnModal from "../ModalComponents/AddPyrolysisKilnModal";
import AddMesuringVesselModal from "../ModalComponents/AddMeasuringVesselModal";
import AssignBiomassModal from "../ModalComponents/AssignBiomassModal";
import ViewSequestrationModal from "../ModalComponents/ViewSequestrationModal";
import AddSoilPitModal from "../ModalComponents/AddSoilPitModal";
import AddNewProjectModal from "../ModalComponents/AddNewProjectModal";
import ViewArtisanOrProjectModal from "../ModalComponents/ViewArtisanOrProjectModal";
import ApproveArtisanModal from "../ModalComponents/ApproveArtisanModal";

interface Props extends ModalObj {}

const Modal = ({
    modalType,
    modalProps,
    modalTitle,
    showCloseBtn,
    bgColor,
    textColor,
}: Props) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideModal());
    };

    const ref = useEscClose(() => handleClose());

    let Component: React.FC<any> | null = null;

    switch (modalType) {
        case ADD_CATEGORY_MODAL:
            Component = AddCategoryModal;
            break;
        case ADD_CROP_MODAL:
            Component = AddCropModal;
            break;
        case EDIT_PRODUCT_MODAL:
            Component = EditProductModal;
            break;
        case ADD_SEED_TYPE_MODAL:
            Component = AddSeedTypeModal;
            break;
        case ADD_SEED_VARIETY_MODAL:
            Component = AddSeedVarietyModal;
            break;
        case ADD_ICAR_MODAL:
            Component = AddICARModal;
            break;
        case EDIT_ICAR_MODAL:
            Component = EditICARModal;
            break;
        case ADD_CROP_VARIETY_MODAL:
            Component = AddCropVarietyModal;
            break;
        case ADD_FERTILISER_MODAL:
            Component = AddFertiliserModal;
            break;
        case ADD_FERTILISER_COMBINATION_MODAL:
            Component = AddFertiliserCombinationModal;
            break;
        case VIEW_PREPARATION_MODAL:
            Component = ViewPreparationModal;
            break;
        case VIEW_PYROLYSIS_MODAL:
            Component = ViewPyrolysisModal;
            break;
        case VIEW_BIOCHAR_MODAL:
            Component = ViewBioCharModal;
            break;
        case ADD_BIOMASS_COMPOSE_MODAL:
            Component = AddBiomassComposeModal;
            break;
        case ADD_PYROLYSIS_KILN_MODAL:
            Component = AddPyrolysisKilnModal;
            break;
        case ADD_SOIL_PIT_MODAL:
            Component = AddSoilPitModal;
            break;
        case ADD_MEASURING_VESSEL_MODAL:
            Component = AddMesuringVesselModal;
            break;
        case ASSIGN_BIOMASS_MODAL:
            Component = AssignBiomassModal;
            break;
        case VIEW_SEQUESTRATION_MODAL:
            Component = ViewSequestrationModal;
            break;
        case ADD_NEW_PROJECT_MODAL:
            Component = AddNewProjectModal;
            break;
        case VIEW_ARTISAN_OR_PROJECT_MODAL:
            Component = ViewArtisanOrProjectModal;
            break;
        case APPROVE_ARTISAN_MODAL:
            Component = ApproveArtisanModal;
            break;
        default:
            Component = null;
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflowX = "hidden";
            document.body.style.overflowY = "auto";
        };
    }, []);

    return (
        <div
            className="backdrop fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full overflow-y-auto overflow-x-auto m-auto bg-black bg-opacity-60"
            style={{
                maxHeight: "100vh",
            }}
        >
            <div className="absolute top-16 flex flex-col items-center">
                <div
                    className={`relative rounded modal flex flex-col`}
                    style={{
                        maxWidth: "80rem",
                        background: bgColor ? bgColor : "#ffffff",
                        color: textColor ? textColor : "inherit",
                    }}
                    ref={ref}
                >
                    {showCloseBtn === true && (
                        <button
                            onClick={handleClose}
                            type="button"
                            className="modal__close-btn absolute right-2 z-10 top-3"
                        >
                            <MdClose
                                color={textColor ? textColor : "inherit"}
                                size={21}
                            />
                        </button>
                    )}
                    {modalTitle && (
                        <div className="modal__title">
                            <h3 className="text-xl font-semibold pl-4 px-2 py-4">
                                {modalTitle}
                            </h3>
                        </div>
                    )}

                    <div
                        className={`w-full z-50 ${showCloseBtn ? "mr-4" : ""}`}
                    >
                        {/* desctucturing undefinded value inside object, it will ignore */}

                        {Component !== null && <Component {...modalProps} />}
                    </div>
                </div>
                <div className="empty-space pb-14"></div>
            </div>
        </div>
    );
};

export default Modal;
