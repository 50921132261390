import axiosInstance from "../axiosInstance";


export const uploadImage = async (file: object) => {
  const formData = new FormData();
  formData.append("image", file as Blob);

  const result = await axiosInstance.post(`/users/upload/image`, formData);
  return result.data;
};
