import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_PYROLYSIS_KILN_MODAL } from "../../types/constants";
import { MdAdd } from "react-icons/md";

interface UserObj {
  id: string;
  name: string;
  createdAt: string;
}

const PyrolysisKiln = () => {
  const queryClient = useQueryClient();

  const ALL_USERS_COLUMNS = [
    {
      Header: "Pyrolysis_Kiln Id",
      accessor: "pyrolysis_kiln_id",
    },
    {
      Header: "Kiln Name",
      accessor: "kiln_name",
    },
    {
      Header: "Kiln type",
      accessor: "kiln_type",
    },
    {
      Header: "Depth",
      accessor: "depth",
    },
    {
      Header: "Upper diameter",
      accessor: "upper_diameter",
    },
    {
      Header: "Lower diameter",
      accessor: "lower_diameter",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Kiln Photo",
      accessor: "kiln_photo",
      Cell: ({ value }: any) => {
        return <a href={value} className="text-green-400" target="_blank">Open Image</a>;
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getAllPyrolysisKiln = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/pyrolysis_kiln/all`);

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(["getAllPyrolysisKiln", pageIndex, cPageSize, cSortBy, desc, q], getAllPyrolysisKiln);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />
        </div>
        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_PYROLYSIS_KILN_MODAL,
                  modalTitle: "Add Pyrolysis kiln",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default PyrolysisKiln;
