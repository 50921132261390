import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    VIEW_BIOCHAR_MODAL,
    VIEW_PREPARATION_MODAL,
    VIEW_PYROLYSIS_MODAL,
    VIEW_SEQUESTRATION_MODAL,
} from "../../types/constants";

interface UserObj {
    id: string;
    gstin: string;
    name: string;
    companyName: string;
    createdAt: string;
}

const AllBatch = () => {

    const getPreparationDetails = async (value: string) => {
        try {
            const res = await axiosInstance.get(
                "/biomass_batch/preparation/" + value
            );
            dispatch(
                showModal({
                    modalType: VIEW_PREPARATION_MODAL,
                    modalTitle: "View Preparation Details",
                    modalProps: {
                        data: res.data.data,
                    },
                })
            );
        } catch (error: any) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };

    const getPyrolysisDetails = async (value: string) => {
        try {
            const res = await axiosInstance.get(
                "/biomass_batch/pyrolysis/" + value
            );
            dispatch(
                showModal({
                    modalType: VIEW_PYROLYSIS_MODAL,
                    modalTitle: "View Pyrolysis Details",
                    modalProps: {
                        data: res.data.data,
                    },
                })
            );
        } catch (error: any) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };

    const getBioCharDetails = async (value: string) => {
        try {
            const res = await axiosInstance.get(
                "/biomass_batch/biochar/" + value
            );
            dispatch(
                showModal({
                    modalType: VIEW_BIOCHAR_MODAL,
                    modalTitle: "View BioChar Details",
                    modalProps: {
                        data: res.data.data,
                    },
                })
            );
        } catch (error: any) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };

    const getSequestrationDetails = async (batchId: string, value: string) => {
        try {
            const res = await axiosInstance.get(
                `/biomass_batch/sequestration/${batchId}/${value}`
            );
            dispatch(
                showModal({
                    modalType: VIEW_SEQUESTRATION_MODAL,
                    modalTitle: "View Sequestration Details",
                    modalProps: {
                        data: res.data.data,
                    },
                })
            );
        } catch (error: any) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };

    const ALL_USERS_COLUMNS = [
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Batch ID",
            accessor: "biomass_batch_id",
        },
        {
            Header: "Artisan ID",
            accessor: "artisan_id",
        },
        {
            Header: "View Preparation",
            accessor: "preparation_id",
            Cell: ({ value }: any) => {
                return (
                    <div>
                        {value ? (
                            <p
                                onClick={() => {
                                    getPreparationDetails(value);
                                }}
                                className="text-green-400 cursor-pointer"
                            >
                                View
                            </p>
                        ) : (
                            <p className="text-red-400">Not Available</p>
                        )}
                    </div>
                );
            },
        },
        {
            Header: "View Pyrolysis Detail",
            accessor: "pyrolysis_id",
            Cell: ({ value }: any) => {
                return (
                    <div>
                        {value ? (
                            <p
                                onClick={() => {
                                    getPyrolysisDetails(value);
                                }}
                                className="text-green-400 cursor-pointer"
                            >
                                View
                            </p>
                        ) : (
                            <p className="text-red-400">Not Available</p>
                        )}
                    </div>
                );
            },
        },
        {
            Header: "View BioChar Details",
            accessor: "biochar_id",
            Cell: ({ value }: any) => {
                return (
                    <div>
                        {value ? (
                            <p
                                onClick={() => {
                                    getBioCharDetails(value);
                                }}
                                className="text-green-400 cursor-pointer"
                            >
                                View
                            </p>
                        ) : (
                            <p className="text-red-400">Not Available</p>
                        )}
                    </div>
                );
            },
        },
        {
            Header: "View Sequestration Details",
            accessor: "sequestration_id",
            Cell: ({ value, row }: any) => {
                return (
                    <div>
                        {value ? (
                            <p
                                onClick={() => {
                                    // getBioCharDetails(value);
                                    getSequestrationDetails(
                                        row.original.biomass_batch_id,
                                        value
                                    );
                                }}
                                className="text-green-400 cursor-pointer"
                            >
                                View
                            </p>
                        ) : (
                            <p className="text-red-400">Not Available</p>
                        )}
                    </div>
                );
            },
        },
        {
            Header: "Date Created",
            accessor: "createdAt",
            Cell: ({ value }: any) => {
                return <p className="">{value.slice(0, 10)}</p>;
                // return <img src={value} className="h-44" />
            },
        },
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [, setSelectedRows] = useState([]);

    let component = null;

    const getAllBiomassBatch = async () => {
        const { data } = await axiosInstance.get(`/biomass_batch/admin/all`);
        return data?.data;
    };

    const { data, error, isLoading } = useQuery<
        UserObj[] | undefined,
        any,
        UserObj[],
        any[]
    >(
        ["getAllBiomassBatch", pageIndex, cPageSize, cSortBy, desc, q],
        getAllBiomassBatch
    );

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => {}}
                    />
                </div>
                {/* <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_CATEGORY_MODAL,
                  modalTitle: "Add a User",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div> */}
            </header>

            {component !== null && component}
        </div>
    );
};

export default AllBatch;
